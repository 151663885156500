import React from "react";
import { IoMailOpenOutline, IoLocationOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";
import logoImage from "../../Assets/MedExpo_logo.jpeg";

const FooterComponent: React.FC = () => {
  const contactItems = [
    {
      type: "email",
      icon: <IoMailOpenOutline className="w-5 h-5 text-black shrink-0" />,
      label: "annualmedicalexpo@gmail.com",
      href: "mailto:annualmedicalexpo@gmail.com",
    },
    // {
    //   type: "phone",
    //   icon: <FiPhone className="w-5 h-5 text-black shrink-0" />,
    //   label: "0123456789",
    //   href: "tel:0123456789",
    // },
    {
      type: "address",
      icon: <IoLocationOutline className="w-5 h-5 text-black shrink-0" />,
      label: "2030 NE 36TH Ave, Ocala, FL 34470",
    },
  ];

  const items = [
    {
      imageUrl: "https://tailwindcss.com/img/card-left.jpg",
      title: "The Coldest Sunset",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    },
    {
      imageUrl: "https://tailwindcss.com/img/card-left.jpg",
      title: "A New Dawn",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  return (
    <footer className="bg-gradient-to-r from-teal-700 to-transparent text-black">
      <svg
        className="footer-wave-svg w-full h-[30px] -top-px relative block bg-transparent"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 100"
        preserveAspectRatio="none"
      >
        <path
          className="footer-wave-path fill-sky-50"
          d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
        />
      </svg>
      <div className="p-6">
        <div className="max-w-screen-xl px-4 pt-16 pb-6 mx-auto sm:px-6 lg:px-8 lg:pt-24">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            {/* logo */}
            <div>
              <div className="flex justify-center text-black sm:justify-start">
                <Link to="/">
                  <img src={logoImage} alt="Logo" className="w-40" />
                </Link>
              </div>

              <p className="max-w-md mx-auto mt-6 leading-relaxed text-center text-black sm:max-w-xs sm:mx-0 sm:text-left">
                Raising The Standards in Health Care
              </p>

              <ul className="flex justify-center gap-6 mt-8 md:gap-8 sm:justify-start">
                <li>
                  <a
                    href="/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-black transition hover:text-white"
                  >
                    <span className="sr-only">Facebook</span>
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-black transition hover:text-white"
                  >
                    <span className="sr-only">Instagram</span>
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-black transition hover:text-white"
                  >
                    <span className="sr-only">Twitter</span>
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            {/* Photos and Contact Us */}
            <div className="grid grid-cols-1 gap-8 lg:col-span-2 md:grid-cols-2">
              <div className="text-center sm:text-left">
                <p className="text-lg font-medium text-black">Upcoming Expo</p>
                <p className="max-w-md mx-auto mt-6 leading-relaxed text-center text-black sm:max-w-xs sm:mx-0 sm:text-left">
                22nd Annunal Medical Expo<br/> 
                January 25, 2025<br/>
                8 am - 4 pm<br/>
                2030 NE 36th Ave, Ocala, FL 34470
                
                
                
              
              </p>{/* <div className="mt-8">
                  {items.map((item, index) => (
                    <div className="flex mb-3" key={index}>
                      <div
                        className="h-20 w-3/12 bg-cover"
                        style={{
                          backgroundImage: `url('${item.imageUrl}')`,
                        }}
                        title={item.title}
                      ></div>
                      <div className="pl-3 w-9/12">
                        <div className="font-bold text-sm">{item.title}</div>
                        <p className="text-gray-700 text-sm">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
              <div className="text-center sm:text-left">
                <p className="text-lg font-medium text-black">Contact Us</p>
                <ul className="mt-8 space-y-4 text-sm">
                  {contactItems.map((item, index) => (
                    <li key={index}>
                      {item.type !== "address" ? (
                        <a
                          className="flex items-center justify-center sm:justify-start gap-1.5 group"
                          href={item.href}
                        >
                          {item.icon}
                          <span className="text-black transition group-hover:text-white/75">
                            {item.label}
                          </span>
                        </a>
                      ) : (
                        <div className="flex items-start justify-center sm:justify-start gap-1.5">
                          {item.icon}
                          <address className="-mt-0.5 not-italic text-black">
                            {item.label}
                          </address>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="pt-6 mt-12 border-t border-gray-800">
            <div className="text-center sm:flex sm:justify-between sm:text-left">
              <p className="text-sm text-black">
                <span className="block sm:inline">All rights reserved.</span>
                &nbsp;&nbsp;
                <a
                  className="inline-block text-black underline transition hover:text-white"
                  href="/"
                >
                  Terms & Conditions
                </a>
                &middot;
                <a
                  className="inline-block text-black underline transition hover:text-white"
                  href="/"
                >
                  Privacy Policy
                </a>
              </p>

              <p className="mt-4 text-sm text-black sm:order-first sm:mt-0">
                &copy; 2024 The India Association Cultural and Educational
                Center
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
