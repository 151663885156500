import React from "react";
import MainLayouts from "../../Layout/MainLayout";
import { FaLocationDot, FaPhone, FaClock } from "react-icons/fa6";
import Contact from "../../Component/Contact/Contact";
import AddressMap from "../../Component/AddressMap/AddressMap";

const ContactUs: React.FC = () => {
  const infoItems = [
    {
      icon: <FaLocationDot />,
      title: "Our Address",
      details: ["The India Association Cultural And Educational Center", "2030 NE 36TH Ave, Ocala, FL 34470"],
    },
    {
      icon: <FaPhone />,
      title: "Contact",
      details: ["Mail: annualmedicalexpo@gmail.com"],
    },
    
  //  {
  //     icon: <FaClock />,
  //     title: "Working hours",
  //     details: [
  //       "Monday - Friday: 08:00 - 17:00",
  //       "Saturday & Sunday: 08:00 - 12:00",
  //     ],
  //   },
  ];
  return (
    <MainLayouts>
      <section>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <div className="mb-4">
            <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 text-3xl">
                Contact Us
              </h2>
              <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600">
                 
              </p>
            </div>
          </div>
          <div className="flex items-stretch justify-center">
            <div className="grid md:grid-cols-2">
              <div className="h-full pr-6">
                <p className="mt-3 mb-12 text-lg text-gray-600">
                We’d love to hear from you! If you have any questions about the Annual Medical Expo, sponsorship opportunities, or event details, please don’t hesitate to reach out. Our team is here to assist you.
                </p>
                <ul className="mb-6 md:mb-0">
                  {infoItems.map((item, index) => (
                    <li className="flex" key={index}>
                      <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                        {item.icon}
                      </div>
                      <div className="ml-4 mb-4">
                        <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
                          {item.title}
                        </h3>
                        {item.details.map((detail, i) => (
                          <p className="text-gray-600" key={i}>
                            {detail}
                          </p>
                        ))}
                      </div>
                    </li>
                  ))}
                  <p className="mt-3 mb-12 text-lg text-gray-600">
                  You can also use the contact form on our website, and we’ll get back to you promptly. We look forward to connecting with you!
                </p>
                </ul>
              </div>
              <div className="card h-fit max-w-6xl p-5 md:p-12">
                <h2 className="mb-4 text-2xl font-bold">
                  Ready to Get Started?
                </h2>
                <Contact />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-10 h-dvh">
          <AddressMap />
        </div>
      </section>
    </MainLayouts>
  );
};

export default ContactUs;
