import React from "react";

const EventAds: React.FC<any> = ({ adsData }) => {
  const ulRef = React.createRef<HTMLUListElement>();

  React.useEffect(() => {
    if (ulRef.current) {
      const ul = ulRef.current;
      const duplicate = ul.cloneNode(true) as HTMLUListElement;
      duplicate.setAttribute("aria-hidden", "true");
      ul.parentNode?.insertBefore(duplicate, ul.nextSibling);
    }
  }, []);

  return (
    <div className="w-full inline-flex flex-nowrap overflow-hidden">
      <ul
        ref={ulRef}
        className="flex items-center justify-center md:justify-start animate-infinite-scroll"
      >
        {adsData.map((ad: { title: string }, index: number) => (
          <div
            className="whitespace-nowrap text-sm font-medium mx-8 w-full"
            key={index}
          >
            {ad.title}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default EventAds;
