import React, { useState } from "react";
import * as Yup from "yup";
import InputFieldComponent from "../../Component-CSS/Input/InputFieldComponent";
import UseFormikForm from "../../Component-CSS/UseFormikForm/UseFormikForm";
import { FaPhoneFlip, FaUserPen } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import TextareaComponent from "../../Component-CSS/Textarea/TextareaComponent";
import axiosInstance from "../../Services/Interceptors/axiosInstance";

const Contact: React.FC = () => {
  const initialValues = { name: "", email: "", phoneNumber: "", message: "" };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Enter value"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email format is incorrect"
      )
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    message: Yup.string().required("Enter value"),
  });

  // connected User
  const [message, setMessage] = useState<string>("");
  const handleForm = async (values: any) => {
    try {
      const results = await axiosInstance.post(
        "/api/Contact/SaveConnectedUser",
        values
      );
      if (results.status === 200) {
        setMessage("Add your Register");
      }
    } catch (error) {
      console.error(`Error fetching from post method:`, error);
    }
  };
  return (
    <UseFormikForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleForm}
      formClassName="bg-white p-4"
    >
      {(formik, handleSubmit) => (
        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
          <div className="grid gap-4 md:grid-cols-2">
            <div className="relative">
              <InputFieldComponent
                label="Name"
                type="text"
                name="name"
                onChange={(event) => {
                  formik.setFieldValue("name", event.target.value);
                }}
                labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                onBlur={formik.onBlur}
              />
              <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                <FaUserPen />
              </div>
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500 font-semibold">
                  {formik.errors.name}
                </div>
              ) : null}
            </div>
            <div className="relative">
              <InputFieldComponent
                label="Phone No"
                type="text"
                name="phoneNumber"
                onKeyPress={(event: any) =>
                  !/^\d$/.test(event.key) && event.preventDefault()
                }
                onChange={(event) => {
                  formik.setFieldValue("phoneNumber", event.target.value);
                }}
                maxLength={10}
                labelClassName="text-gray-800 text-sm mb-2 block font-bold"
              />
              <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                <FaPhoneFlip />
              </div>
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="text-red-500 font-semibold">
                  {formik.errors.phoneNumber}
                </div>
              ) : null}
            </div>
          </div>
          <div className="relative">
            <InputFieldComponent
              label="Email"
              type="email"
              name="email"
              onChange={(event) => {
                formik.setFieldValue("email", event.target.value);
              }}
              labelClassName="text-gray-800 text-sm mb-2 block font-bold"
              onBlur={formik.onBlur}
            />
            <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
              <MdEmail />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 font-semibold">
                {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className="relative">
            <TextareaComponent
              label="Message"
              name="message"
              onChange={(event) => {
                formik.setFieldValue("message", event.target.value);
              }}
              labelClassName="text-gray-800 text-sm mb-2 block font-bold"
              onBlur={formik.onBlur}
              rows={4}
            />
            {formik.touched.message && formik.errors.message ? (
              <div className="text-red-500 font-semibold">
                {formik.errors.message}
              </div>
            ) : null}
          </div>
          <div className="w-1/2 my-2 lg:w-1/4">
            <button
              type="submit"
              className="px-6 py-2 min-w-[120px] text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
            >
              Submit
            </button>
          </div>
          {message && (
            <span className="text-green-600 mt-4 font-semibold text-lg">
              {message}
            </span>
          )}
        </form>
      )}
    </UseFormikForm>
  );
};

export default Contact;
