import React from "react";
import Banner from "../../Assets/BannerImg.png";
import MainLayouts from "../../Layout/MainLayout";

const AboutUs: React.FC = () => {
  const MedicalCoordinatorsList: string[] = [
    "Saumil Karavadia, MD",
    "Bhavesh (Bobby) Patel , RPh",
    "Srisha Rao, MD",
    "Mahesh Vaghela, MD",
    "Anil Gogineni, MD",
    "Anuj Sharma, DO",
    "Hima Mikkilineni, MD",
  ];

  const EventCoordinatorsList: string[] = [
    "Narendra Patel, MD",
    "Neal Patel",
    "Neel Koladiya",
    "Anil Patel",
    "Anju Vasudevan, MD",
    "Binisha Patel",
    //"Diana Ananthan, MD",
    "Hardik Bhatt, MD",
    "Himanshu Patel, RPh",
    "Ivan Soosaipillai, MD",
    "Ketan Doshi, MD",
    //"Pradeep Saravanapavan, MD",
    "Pravina Cacodcar",
    "Rajnikant Patel, MD",
    "Sanjay Patel, MD",
    "Sharad Bajaj, MD",
    "Siva Vaidya",
    "Srinivas Murthy, MD",
    "Sunil Patidar",
    "Suresh Patidar",
    "Tushar Mavani, DPT",
    "Vinay Bhatt, RPH",
  ];

  return (
    <MainLayouts>
      <div>
        {/* About us info */}
        <div className="relative min-h-screen overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <img
              src={Banner}
              className="w-full h-full object-cover"
              alt="Banner Image"
            />
          </div>
          <div className="absolute inset-0 flex items-center justify-center p-4 sm:p-6 md:p-8">
            <div className="p-4 sm:p-6 md:p-8 lg:p-12 max-w-3xl bg-white bg-opacity-70 rounded-lg shadow-xl text-center">
              <h5 className="mb-4 text-lg sm:text-xl lg:text-2xl font-bold text-gray-900">
                Welcome to the Annual Medical Expo of North Central
                Florida.
              </h5>
              <p className="mb-5 text-sm sm:text-base lg:text-lg text-gray-700">
                The Medical Expo Committee proudly presents the largest Medical
                Expo in Ocala at the India Association Cultural and Educational
                Center. Over 300 Physicians, PA’s, Nurses, and Medical Personnel
                attend the Expo annually to educate and network. Sincere thanks
                to our Vendors and Sponsors for helping make the event a
                success. Some proceeds will go to local and national charities,
                including Interfaith Emergency Services, Arc of Marion, Marion
                County Children’s Alliance, and the Sheriff of Marion county.
                Please support our Sponsors by visiting our Exhibit Booths,
                Lectures, and Demonstrations. Enjoy the Expo, a taste of Indian
                Culture with food and entertainment, and most of all, enjoy the
                medical camaraderie.
              </p>
              <p className="text-sm sm:text-base lg:text-lg text-gray-700">
                Sincerely, <br />
                <span className="text-lg sm:text-xl font-bold text-gray-900">
                  The Medical Expo Committee
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* medical expo Committee */}
        <section className="mb-10 relative">
          <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl text-center">
            <h2 className="text-gray-900 text-lg sm:text-xl lg:text-2xl font-bold font-manrope leading-normal text-center mb-10">
              Medical Expo Committee 2025
            </h2>

            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <div className="max-w-sm mx-auto p-4 shadow-md rounded-lg border-t-2 border-teal-400 bg-white/50">
                  <div className="flex justify-between pb-4">
                    <p className="font-bold text-md sm:text-lg lg:text-xl">
                      Medical Coordinators
                    </p>
                  </div>
                  <ul className="flex flex-col pl-1">
                    {[...MedicalCoordinatorsList].sort().map((tool, index) => (
                      <li key={index} className="border-b py-2">
                        <span className="">{tool}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div>
                <div className="max-w-xl mx-auto p-4 shadow-md rounded-lg border-t-2 border-teal-400 bg-white/50">
                  <div className="flex justify-between pb-4">
                    <p className="font-bold text-md sm:text-lg lg:text-xl">
                      Event Coordinators
                    </p>
                  </div>
                  <ul className="flex flex-col pl-1">
                    <div className="grid md:grid-cols-2 lg:grid-cols-3">
                      {[...EventCoordinatorsList].sort().map((tool, index) => (
                        <li key={index} className="border-b py-2">
                          <span className="">{tool}</span>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pioneer Members */}
         <section className="py-10 relative">
          <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
            <div className="w-full justify-start items-center gap-12 grid lg:grid-cols-2 grid-cols-1">
              <div className="w-full justify-center items-start gap-6 grid sm:grid-cols-2 grid-cols-1 lg:order-first order-last">
                <div className="relative md:pt-24 lg:justify-center sm:justify-end justify-start items-start gap-2.5 flex">
                  {/* <img
                    className=" rounded-xl object-cover"
                    src="https://pagedone.io/asset/uploads/1717741205.png"
                    alt="about Us image"
                  /> */}
                  <div className="absolute bottom-0 start-0 end-0 bg-white opacity-85">
                    <div className="px-4 py-2">
                      <h3 className="text-lg font-bold text-black">
                        Jay Panchal
                      </h3>
                      <p className="text-xs text-black">MD</p>
                    </div>
                  </div>
                </div>
                <div className="relative">
                  {/* <img
                    className="sm:ml-0 ml-auto rounded-xl object-cover"
                    src="https://pagedone.io/asset/uploads/1717741215.png"
                    alt="about Us image"
                  /> */}
                  <div className="absolute bottom-0 start-0 end-0 bg-white opacity-85">
                    <div className="px-4 py-2">
                      <h3 className="text-lg font-bold text-black">
                        Tina Chandra
                      </h3>
                      <p className="text-xs text-black">DDS</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
                <div className="w-full flex-col justify-center items-start gap-8 flex">
                  <div className="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                    <h2 className="text-gray-900 text-2xl font-bold font-manrope leading-normal lg:text-start text-center">
                      Pioneer Members
                    </h2>
                    <p className="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                      Every project we've undertaken has been a collaborative
                      effort, where every person involved has left their mark.
                      Together, we've not only constructed buildings but also
                      built enduring connections that define our success story.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
      </div>
    </MainLayouts>
  );
};

export default AboutUs;
