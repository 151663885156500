import React from "react";
import MainLayouts from "../../Layout/MainLayout";
import Banner from "../../Assets/BannerImg.png";
import { GiCheckMark } from "react-icons/gi";
import { FaHandHoldingHeart } from "react-icons/fa6";

const Sponsors: React.FC = () => {
  const GrandSponsorsList = [
    { name: " sanjay prasad", title: "CFP" },
    { name: "sumitomo pharma", title: "" },
  ];

  const PlatinumList = [
    "ABBOTT LABS",
    "ABBVIE PHARMACEUTICALS",
    "ADVENT HEALTH",
    "AMGEN MEDICAL",
    "ASTRAZENECA",
    "BOSTON SCIENTIFIC",
    "BRISTOL MYER SQUIBB",
    "CARIS LIFE SCIENCES",
    "CERAS HEALTH",
    "CHANDRA WELLNESS CENTER",
    "DAIICHI SANKYO",
    "DOCTORS URGENT CARE",
    "ENCOMPASS HEALTH REHAB",
    "FLORIDA CANCER SPECIALISTS",
    "GENTIVA HOSPICE",
    "HAWTHORNE & TIMBERRIDGE REHAB CENTER",
    "HCA FLORIDA",
    "HOSPICE OF MARION COUNTY",
    "INCYTE",
    "JANSSEN",
    "KESTRA MEDICAL",
    "LAB CURRENT",
    "LIFECARE CENTER OF OCALA",
    "MEDTRONIC",
    "MERCK",
    "NOVO NORDISK",
    "OTSUKA",
    "PET CT SERVICES",
    "PHARMAESSENTIA",
    "SI PARADIGM LABS",
    "SUMITOMO PHARMA",
    "TD BANK",
    "TRILOGY HOME HEALTH",
    "UF HEALTH SHANDS",
  ];
    
      const GoldList = [
    "A 1 PHYSICAL THERAPY",
    "NIRVANA MEDICAL SPA",
    "CARDIOVASCULAR INSTITUTE OF CENTRAL FLORIDA",
"CENTRAL FLORIDA CANCER & BLOOD CENTER - RAVI KOTI, MD"
,"GASTROENTEROLOGY ASSOCIATES OF OCALA"
,"NIRVANA MEDICAL SPA"
,"OCALA KIDNEY GROUP"
,"OCALA LUNG & CRITICAL CARE"
,"OCALA ONCOLOGY FLORIDA CANCER AFFILIATES"
,"OCALA PHARMACY - BHAVESH (BOBBY) PATEL, RPH"
,"SHARMA INSTITUTE OF PAIN MEDICINE & REHAB. - DR. ANUJ SHARMA"
,"SIVA COMPUTERS - FORTE CLIENT - SIVA VAIDYA",
  ];

  const SpecialThanksList = [
"ADVENTHEALTH OCALA CME STAFF"
,"BOOTH SET UP - SAM PATIDAR & SUNIL PATIDAR"
,"DIYA YOUTH GROUP -PARKING ATTENDANTS"
,"FRONT DESK - SHAI, SONIA, CAROL, SAMANTHA"
,"GOOD LIFE"
,"MARION COUNTY SHERIFF OFFICE· SECURITY MEDICAL EXPO TEAM"
,"MEDICAL EXPO WAIT STAFF - KIM, CATLYN, CHRIS, MYRA, HENRY, LEE J, MARCUS"
,"OCALA GAZETTE"
,"OCALA MAGAZJNE"
,"OCALA STYLE MAGAZINE"
,"OCALA'S DOWNTOWN DINER"
,"PANTHER PRINT SOLUTIONS"
,"SIVA COMPUTERS - FORTE CLIENT"
,"SPINNING CANVAS DANCE PAK, TAMPA"
,"THE FLAME INDIAN KITCHEN"
,"TONY'S SUSHI RESTAURANT",
  ];

  return (
    <MainLayouts>
      {/* event sponsors */}
      <div
        className="relative bg-cover object-cover bg-center"
        style={{
          backgroundImage: `url(${Banner})`,
          height: "calc(100vh-31px)",
        }}
      >
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h2 className="text-2xl font-bold text-red-700 uppercase mb-6">
              event sponsors
            </h2>
            <p className="mb-5 text-base text-black sm:text-lg">
              Welcome to the 22<sup>nd</sup>&nbsp; Annual Medical Expo of North
              Central Florida. <br />
              Sincere thanks to all our sponsors for supporting this event
            </p>
          </div>
        </div>
      </div>

      {/* grand sponsors */}
      <div className="relative bg-white">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-sky-50"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h2 className="text-2xl font-bold text-red-700 uppercase mb-6">
              grand sponsors
            </h2>
            <div className="flex flex-wrap gap-6 p-6 justify-center text-lg">
              {GrandSponsorsList.map((list, index) => (
                <div
                  className="capitalize	bg-gray-100 flex-grow text-black border-l-8 border-teal-500 rounded-md px-3 py-2 w-full md:w-5/12 lg:w-3/12"
                  key={index}
                >
                  {list.name}
                  <div className="text-gray-500 font-thin text-sm pt-1">
                    <span> {list.title}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Platinum */}
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="relative max-w-5xl sm:mx-auto sm:text-center">
            <h2 className="text-2xl font-bold text-red-700 uppercase md:mb-6">
              Platinum
            </h2>
            <div className="flex flex-wrap sm:mx-auto sm:mb-2 p-4">
              {[...PlatinumList].sort().map((list, index) => (
                <div className="p-2 sm:w-1/2 w-full" key={index}>
                  <div className="bg-white/75 rounded flex p-4 h-full items-center">
                    <GiCheckMark className="text-teal-500 flex-shrink-0 mr-4" />
                    <span className="font-semibold capitalize">{list}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* gold */}
      <div className="relative bg-white">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-sky-50"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"></path>
          </svg>
        </div>
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="relative max-w-4xl sm:mx-auto sm:text-center">
            <h2 className="text-2xl font-bold text-red-700 uppercase mb-6">
              gold
            </h2>
            <div className="mt-2 relative z-10 mb-6">
              <ul className="text-left font-medium text-lg leading-none ">
                <div className="grid md:grid-cols-2">
                  {[...GoldList].sort().map((list, index) => (
                    <li
                      className="border-teal-200 divide-y divide-teal-200"
                      key={index}
                    >
                      <div className="py-3.5 w-full flex items-center text-teal-500">
                        <span className="ml-5 mr-2.5 w-1 h-7 bg-teal-500 rounded-r-md" />
                        <span className="text-black capitalize">{list}</span>
                      </div>
                    </li>
                  ))}
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* special thanks */}
      <div className="relative">
        <div className="p-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="relative max-w-5xl sm:mx-auto sm:text-center">
            <h2 className="text-2xl font-bold text-red-700 uppercase mb-6">
              special thanks
            </h2>
            <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
              {[...SpecialThanksList].sort().map((list, index) => (
                <div
                  className="bg-white flex items-center rounded-md cursor-pointer transition duration-500 shadow-sm hover:shadow-md hover:shadow-teal-400"
                  key={index}
                >
                  <div className="w-16 p-2 shrink-0">
                    <FaHandHoldingHeart className="text-teal-500 w-6 h-6 mx-4" />
                  </div>
                  <div className="p-2">
                    <p className="font-semibold">{list}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayouts>
  );
};

export default Sponsors;
