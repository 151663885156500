import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { RoutesPath } from "./Constant/RoutesConstant";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Registration from "./Pages/Registration/Registration";
import Sponsors from "./Pages/Sponsors/Sponsors";
import Gallery from "./Pages/Gallery/Gallery";
import ContactUs from "./Pages/ContactUs/ContactUs";

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path={RoutesPath.HOME} element={<Home />} />
        <Route path={RoutesPath.ABOUT_US} element={<AboutUs />} />
        <Route path={RoutesPath.REGISTRATION} element={<Registration />} />
        <Route path={RoutesPath.SPONSORS} element={<Sponsors />} />
        <Route path={RoutesPath.GALLERY} element={<Gallery />} />
        <Route path={RoutesPath.CONTACT} element={<ContactUs />} />
      </Routes>
    </>
  );
};

export default App;
