import React from "react";

const AddressMap: React.FC = () => {
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3482.5365072893633!2d-82.089991325466!3d29.207769757728226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e62ce099816435%3A0xb066b03348a5fcd8!2sIndia%20Association%20Cultural!5e0!3m2!1sen!2sin!4v1731120904908!5m2!1sen!2sin";

  return (
    <iframe
      src={mapUrl}
      width="100%"
      height="100%"
      title="map"
      // style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
    />
  );
};

export default AddressMap;
