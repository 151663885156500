import React from "react";
import MainLayouts from "../../Layout/MainLayout";
//import Image
const localImages = [
  require("../../Assets/Images/image1.jpg"),
  require("../../Assets/Images/image2.jpg"),
  require("../../Assets/Images/image3.jpg"),
  require("../../Assets/Images/image4.jpg"),
  require("../../Assets/Images/image5.jpg"),
  require("../../Assets/Images/image6.jpg"),
  require("../../Assets/Images/image7.jpg"),
  require("../../Assets/Images/image8.jpg"),
  require("../../Assets/Images/image9.jpg"),
  require("../../Assets/Images/image10.jpg"),
  require("../../Assets/Images/image11.jpg"),
  require("../../Assets/Images/image12.jpg"),
];

const Gallery: React.FC = () => {
  const images = [
    [
      localImages[0],
      localImages[1],
      localImages[2],
    ],
    [
      localImages[3],
      localImages[4],
      localImages[5],
    ],
    [
      localImages[6],
      localImages[7],
      localImages[8],
    ],
    [
      localImages[9],
      localImages[10],
      localImages[11],
    ],
  ];

  return (
    <MainLayouts>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-10 mt-4">
        {images.map((items, index) => (
          <div key={index} className="grid gap-4">
            {items.map((src, index) => (
              <div key={index}>
                <img
                  className="h-auto max-w-full rounded-lg"
                  src={src}
                  alt={`Gallery image ${index + 1}`}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </MainLayouts>
  );
};

export default Gallery;
