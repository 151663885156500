import React, { useEffect, useState } from "react";
import UseFormikForm from "../../Component-CSS/UseFormikForm/UseFormikForm";
import * as Yup from "yup";
import InputFieldComponent from "../../Component-CSS/Input/InputFieldComponent";
import MainLayouts from "../../Layout/MainLayout";
import { FaUserPen, FaPenToSquare, FaPhoneFlip } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import Dropdown from "../../Component-CSS/Dropdown/Dropdown";
import axiosInstance from "../../Services/Interceptors/axiosInstance";
import RegistrationConfirm from "./RegistrationConfirm";

const Registration = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    amClassID: "",
    pmClassID: "",
    roleID: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Enter value"),
    lastName: Yup.string().required("Enter value"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email format is incorrect"
      )
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    amClassID: Yup.string().required("Select Value"),
    pmClassID: Yup.string().required("Select Value"),
    roleID: Yup.string().required("Select Value"),
  });

  const [message, setMessage] = useState<boolean>(false);
  const [isloading, setIsLoading] = useState<boolean>(false); // Loader state

  const handleForm = async (values: any) => {
    setIsLoading(true); // Show loader
    // Add User
    try {
      const results = await axiosInstance.post("/api/User/AddUser", values);
      if (results.status === 200) {
        setMessage(true);
        // send email
        axiosInstance.post("/api/User/SendEmail", values)
      }
    } catch (error) {
      console.error(`Error fetching from post method:`, error);
    } finally {
      setIsLoading(false); // stop loader
    }
  };

  const rolesOptions = [
    { value: 1, label: "Visitor" },
    { value: 2, label: "Medical Doctor" },
    { value: 3, label: "Nurse Practitioner" },
    { value: 4, label: "Physician Assistant" },
  ];

  // options get am and pm class
  const [amClassOptions, setAmClassOptions] = useState([]);
  const [pmClassOptions, setPmClassOptions] = useState([]);

  const fetchClasses = async (endpoint: any, setOptions: any) => {
    try {
      const response = await axiosInstance.get(endpoint);
      const options = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(options);
    } catch (error) {
      console.error(`Error fetching classes from ${endpoint}:`, error);
    }
  };

  useEffect(() => {
    fetchClasses("/api/User/GetAMClasses", setAmClassOptions);
    fetchClasses("/api/User/GetPMClasses", setPmClassOptions);
  }, []);

  return (
    <MainLayouts>
      <div className="max-w-screen-xl mx-auto py-10 lg:px-20">
        <div className="w-full p-8 my-4 shadow-lg  md:px-12 rounded-2xl bg-white">
          {message ? (
            <>
              <h1 className="mb-6 text-xl font-bold leading-tight tracking-tight md:text-2xl">
                Thank You,
              </h1>
              <RegistrationConfirm />
            </>
          ) : (
            <>
              <h1 className="mb-6 text-xl font-bold leading-tight tracking-tight md:text-2xl">
                Register For 2025 Medical Expo
              </h1>
              <UseFormikForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleForm}
                formClassName=""
              >
                {(formik, handleSubmit) => (
                  <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                    <div className="grid gap-4 md:grid-cols-2">
                      <div className="relative">
                        <InputFieldComponent
                          label="First Name"
                          type="text"
                          name="firstName"
                          onChange={(event) => {
                            formik.setFieldValue("firstName", event.target.value);
                          }}
                          labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                          onBlur={formik.onBlur}
                        />
                        <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                          <FaUserPen />
                        </div>
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="text-red-500 font-semibold">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                      <div className="relative">
                        <InputFieldComponent
                          label="Last Name"
                          type="text"
                          name="lastName"
                          onChange={(event) => {
                            formik.setFieldValue("lastName", event.target.value);
                          }}
                          labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                        />
                        <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                          <FaPenToSquare />
                        </div>
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="text-red-500 font-semibold">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                      <div className="relative">
                        <InputFieldComponent
                          label="Email"
                          type="email"
                          name="email"
                          onChange={(event) => {
                            formik.setFieldValue("email", event.target.value);
                          }}
                          labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                          onBlur={formik.onBlur}
                        />
                        <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                          <MdEmail />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-500 font-semibold">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="relative">
                        <InputFieldComponent
                          label="Phone No"
                          type="text"
                          name="phoneNumber"
                          onKeyPress={(event: any) =>
                            !/^\d$/.test(event.key) && event.preventDefault()
                          }
                          onChange={(event) => {
                            formik.setFieldValue("phoneNumber", event.target.value);
                          }}
                          maxLength={10}
                          labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                        />
                        <div className="absolute inset-y-0 right-0 top-3 h-full flex items-center pe-3.5 pointer-events-none text-gray-500">
                          <FaPhoneFlip />
                        </div>
                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                          <div className="text-red-500 font-semibold">
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <Dropdown
                          label="Roles"
                          name="roleID"
                          options={rolesOptions}
                          onSelect={(option) =>
                            formik.setFieldValue("roleID", option?.value || "")
                          }
                          labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                        />
                        {formik.touched.roleID && formik.errors.roleID ? (
                          <div className="text-red-500 font-semibold">
                            {formik.errors.roleID}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <Dropdown
                          label="AM Class"
                          name="amClassID"
                          options={amClassOptions}
                          onSelect={(option) =>
                            formik.setFieldValue("amClassID", option?.value || "")
                          }
                          labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                        />
                        {formik.touched.amClassID && formik.errors.amClassID ? (
                          <div className="text-red-500 font-semibold">
                            {formik.errors.amClassID}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <Dropdown
                          label="PM Class"
                          name="pmClassID"
                          options={pmClassOptions}
                          onSelect={(option) =>
                            formik.setFieldValue("pmClassID", option?.value || "")
                          }
                          labelClassName="text-gray-800 text-sm mb-2 block font-bold"
                        />
                        {formik.touched.pmClassID && formik.errors.pmClassID ? (
                          <div className="text-red-500 font-semibold">
                            {formik.errors.pmClassID}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="w-1/2 my-2 lg:w-1/4">
                      <button
                        type="submit"
                        className="px-6 py-2 min-w-[120px] text-center text-white bg-red-700 border border-red-700 rounded hover:bg-transparent hover:text-red-700"
                        disabled={isloading} // Disable button while loading
                      >
                        Submit

                      </button>

                      {isloading && (
                        <span
                          style={{
                            marginLeft: "10px",
                            width: "30px",
                            height: "30px",
                            border: "4px solid blue",
                            borderRadius: "50%",
                            borderTop: "4px solid transparent",
                            animation: "spin 1s linear infinite",
                            color: "blue"
                          }}
                        />
                      )}
                    </div>
                  </form>
                )}
              </UseFormikForm>
            </>
          )}
        </div>
      </div>
      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </MainLayouts>
  );
};

export default Registration;
