import React from "react";
import HeaderComponent from "../Component-CSS/Header/HeaderComponent";
import FooterComponent from "../Component-CSS/Footer/FooterComponent";
import { adsDataProps, navItemsProps } from "../Models/types";
import { RoutesPath } from "../Constant/RoutesConstant";
import EventAds from "../Component-CSS/Advertisement/EventAds";
import logoImage from "../Assets/MedExpo_logo.jpeg";

const MainLayouts: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navItems: navItemsProps[] = [
    { navItem: "Home", link: RoutesPath.HOME },
    { navItem: "About Us", link: RoutesPath.ABOUT_US },
    { navItem: "Sponsors", link: RoutesPath.SPONSORS },
    { navItem: "Gallery", link: RoutesPath.GALLERY },
    { navItem: "Contact", link: RoutesPath.CONTACT },
    { navItem: "W9-Form", link: "https://annualmedicalexpo.com/Form%20W9%20-%20IACEC.pdf" },
  ];

  const ads: adsDataProps[] = [
    {
      title:
        "North Central Florida's Largest Medcial Expo for Healthcare Professional", 
    },
    {
      title:
        "CME Class - Domestic Violence, Medical Error, Opioid Prescribing",
    },
    {
      title:
        "Great Food, Entertainment and opportunity to network",
    },
    {
      title:
        "Chance to WIN Gift",
    },
    {
      title:
        "Registration Open Now ",
    },  ];

  return (
    <div className="h-screen overflow-y-auto overflow-x-hidden flex flex-col bg-sky-50">
      <div className="flex-grow">
        <HeaderComponent
          navItems={navItems}
          buttonLink={RoutesPath.REGISTRATION}
          buttonName={"Register"}
          logoImage={logoImage}
        />
        <div className="hidden md:block text-center bg-white text-black font-semibold p-2 uppercase tracking-wide">
          north central florida's largest medical expo in ocala, florida!
        </div>
        <div className="text-center bg-red-700 text-white font-semibold p-2 tracking-wide">
          <EventAds adsData={ads} />
        </div>
        <div className="w-full">{children}</div>
      </div>
      <div>
        <FooterComponent />
      </div>
    </div>
  );
};

export default MainLayouts;
